import React, { useState, useEffect } from 'react';
import '../styles.css';

const ComingSoon = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [isEvenly, setIsEvenly] = useState(false);

  const handleClick = (index) => {
    if (activeIndex === index) {
      console.log('removing');

      setTimeout(() => {
        setIsEvenly(!isEvenly);
        setActiveIndex(null);
      }, 5000);
    } else {
      console.log('opening');
      setActiveIndex(index);
      setIsEvenly(!isEvenly);
    }
  };

  const data = [
    {
      "image": "/images/1725.png",
      "alt": "image 1725",
      "center-icon": "ion-ios-loop-strong",
      "color": "red",
    },
  ];
  return (<>
    <div className="hover-text">
      <span className="hover-word-coming">Coming Soon</span>
    </div>
    <div className={`top-container ${isEvenly ? 'space-between' : ''}`}>
      <div className={`filler ${isEvenly ? 'hidden' : ''}`}></div>
      {
        data.map((item, index) => {
          return (
            <div className="flex">
              <figure className={`snippet-card-static ${activeIndex === index ? 'bio' : ''}`} key={`snippet-card-${index}`} onClick={item.onClick ? () => { item.onClick(index); } : () => { }}>
                {activeIndex === index && SkillsContent(isEvenly)}
                <img src={item.image} alt={item.alt} />
                {item.title &&<figcaption>
                  <div className="left">
                    <h3 className={item.color ? item.color : ''}>{item.title.split(' ')[0]}</h3>
                  </div>
                  <div className="right">
                    <h3 className="white">{item.title.includes(' ') ? item.title.split(' ')[1] : item.title}</h3>
                  </div>
                </figcaption>}
                {item.title &&<div className="center"><i className={item['center-icon']}></i></div>}
                <a href={item.link}></a>
              </figure>
              <a className="buy-me-a-coffee" href="https://www.buymeacoffee.com/nataly.babicheva" target="_blank"><img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" style={{ height: '40px' }} ></img></a>
            </div>
          );
        })
      }
      <div className={`filler ${isEvenly ? 'hidden' : ''}`}></div>
    </div ></>
  );
};

const skills = ["JavaScript TypeScript",
  "ReactJS Node.js",
  "Alexa Skills",
  "AmazonWebServices (EC2, S3, RDS, DynamoDB, CloudFront, Lambda)"]
const skillsLogos = [
  "/images/logos/js.png",
  "/images/logos/ts.png",
  "/images/logos/rjs.png",
  "/images/logos/node.png",
  "/images/logos/aws.png",
  "/images/logos/alexa.png"
]
const SkillsContent = ({ isOpen }) => <div className={`bio-card ${isOpen}? 'open' : 'close'`}>
  <div className="resume-header"><div>Skills</div></div>
  {skills.map((item, index) => <div className='skill-item' key={`skill-item-${index}`}>{item}</div>)}
  <div className='skill-logos'>
    {skillsLogos.map((item, index) =>
      <div className='skill-logo' key={`skill-logo-${index}`}>
        <img src={item} style={{ 'display': 'block', 'opacity': 'unset', 'width': 'unset' }}></img>
      </div>
    )}
  </div>
</div>
export default ComingSoon;
