import { Routes, Route, Outlet, Link } from "react-router-dom";
import Albums from "./components/albums";
import Dashboard from "./components/dashboard";
import ComingSoon from "./components/coming-soon";
import './App.css';

function App() {
  return (
    <div className="App">


      <Routes>
        <Route path="/" element={<ComingSoon />}>
          <Route path="coming-soon" element={<ComingSoon />} />
        </Route>
      </Routes>

    </div>
  );
}

function Layout() {
  return (
    <div>
      {/* A "layout route" is a good place to put markup you want to
          share across all the pages on your site, like navigation. */}
      <nav>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/albums">Albums</Link>
          </li>
          <li>
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li>
            <Link to="/nothing-here">Nothing Here</Link>
          </li>
        </ul>
      </nav>
      <hr />
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

export default App;
